<template>
    <div class="dialog-inner form-col2 layout-image-inner" style="padding-top:0;">
        <div class="layout-image-left-inner">
            <el-row :gutter="20">
                <el-col :span="24">
                    <ImageBox 
                        :width="180"
                        :height="180"
                        :image-url="data.numPhoto ? BASE_URL + data.numPhoto.substring(1) : ''" 
                        :image-list="photoList">
                    </ImageBox>
                    身份证照片
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <ImageBox 
                        :width="180"
                        :height="180"
                        :image-url="data.photo ? BASE_URL + data.photo.substring(1) : ''" 
                        :image-list="photoList">
                    </ImageBox>
                    近照
                </el-col>
            </el-row>
        </div>
        <div class="layout-image-right-inner">
            <div class="base-info">
                <el-row :gutter="20" class="name-box">
                    <el-col :span="24">
                        <div class="data-item">
                            <div class="name">{{ data.name }}</div>
                            <div class="work-type" v-if="data.workName">
                                <el-tag
                                    style="font-size:16px;position: relative;top:-2px;"
                                    type="warning"
                                    effect="plain"
                                    size="medium">
                                    {{ data.workName }}
                                </el-tag>
                            </div>
                            <div class="status">
                                <span v-if="data.status == 0" class="text-warning">未入住</span>
                                <span v-if="data.status == 1" class="text-success">已入住</span>
                                <span v-if="data.status == 2" class="text-info">已退宿</span>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="data-item">
                            <span class="age">{{ data.age }}岁</span>
                            <span class="sex">{{ data.sex == 0 ? "男" : "女" }}</span>
                            <span class="nation">{{ data.nation }}</span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="data-item">
                            证件号码 | {{ data.numId }}
                        </div>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="data-item">
                            出生日期 | {{ data.birthday }}
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="data-item">
                            籍贯 | {{ data.nativePlace }}
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="data-item">
                            手机号码 | {{ data.tel }}
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <div class="data-item">
                            分包单位 | {{ data.supplierName }}
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="time-line mt-10">
                <el-timeline v-if="logList.length > 0">
                    <el-timeline-item
                        v-for="log in logList"
                        :key="log.id"
                        :color="log.color">
                        <div class="r1"><span>{{log.tag}}</span><em>{{log.day}}</em><i>{{log.room}}</i></div>
                        <div class="r2">{{log.time}} {{log.user}}</div>
                    </el-timeline-item>
                </el-timeline>
                <div v-else class="empty">暂无数据</div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import ImageBox from "@/components/ImageBox"

export default {
    name: 'HouseholdPersonDetail',
    components: {
        ImageBox
    },
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            BASE_URL: http.BASE_URL,
            photoList: [],
            logList: [],
            data: {
                numPhoto: '',
                photo: '',
                
                name: '',

                workTypeId: '',
                workName: '',

                status: '',
                age: '',
                sex: '',
                nation: '',
                numId: '',

                birthday: '',
                nativePlace: '',
                tel: '',
                supplierName: '',
            },
            submitting: false
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        ...common,
        ...verify,
        loadData() {
            let params = {};
            params.id = this.params.id;
            let loading = this.load();
            http.get("v1/worker/detail", { params: params }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;

                    this.photoList = [];
                    if (data.numPhoto) {
                        this.photoList.push(this.BASE_URL + data.numPhoto);
                    }

                    if (data.photo) {
                        this.photoList.push(this.BASE_URL + data.photo);
                    }

                    this.logList = [];

                    if (data.checkList) {
                        data.checkList.forEach(x => {
                            let item = {};
                            item.id = x.id;
                            item.tag = this.tagText(x.type);
                            item.room = x.buildName+x.floorName+x.roomName;
                            item.time = x.createTime;
                            item.user = x.userName;
                            item.color = this.tagColor(x.type);
                            item.day = x.day;
                            this.logList.push(item);
                        });
                    }

                    this.data = data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        tagText(type) {
            switch(String(type)) {
                case "1": return "入住";
                case "2":  return "退宿";
                default: return "-";
            }
        },
        tagColor(type) {
            // ['#409eff','#67c23a','#909399','#f56c6c','#e6a23c'][log.colorIndex]
            switch(String(type)) {
                case "1": return "#409eff";
                case "2":  return "#67c23a";
                default: return "-";
            }
        },
    }
};
</script>

<style scoped>
</style>