<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <div class="form-group-title"><h2>基本信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="data-item">
                        <div class="label">所属分包：</div>
                        <div class="text">{{ params.subcontractorName }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="data-item">
                        <div class="label">入住人：</div>
                        <div class="text">
                            <span v-for="(item, index) in personList" :key="item.id">{{ (index > 0 ? '、' : '') + item.name }}</span>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="form-group-title">
                <h2>选择房间</h2>
                <div class="info-box">
                    <span class="sc">可选择宿舍：{{ roomCount }}间</span>
                    <span class="hc">可选床位：{{ bedCount }}个</span>
                </div>
            </div>
            <div class="select-room-box select-room-box-1">
                <el-form-item label-width="0" prop="roomId" style="margin-bottom: 0;">
                    <template v-if="storeyList.length > 0">
                        <div 
                            v-for="storey in storeyList" 
                            :key="storey.storey.id" 
                            class="item clearfix">
                            <div class="st"
                        >{{ storey.buildName }}{{ storey.storey.name }}</div>
                            <ul>
                                <li 
                                    v-for="room in storey.roomList" 
                                    :key="room.id" 
                                    :class="{ 'selected': form.roomId == room.id }"
                                    @click="handleRoomClick(room)"
                                >{{ room.name }}</li>
                            </ul>
                        </div>
                    </template>
                    <div v-else class="empty">暂无数据</div>
                </el-form-item>
            </div>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">宿舍类型：</div>
                        <div class="text">{{ room.roomTypeName }}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">房型：</div>
                        <div class="text">{{ room.checkinNum == '-' ? '-' : room.checkinNum + '人间' }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12" class="mix-cell">
                    <el-form-item label="可入住床位：" prop="notUsedBed">
                        {{ room.checkinNum == '-' ? '-' : room.checkinNum + '人间' }}
                    </el-form-item>
                </el-col>
                <el-col :span="12" class="mix-cell">
                    <el-form-item label="入住时间：" prop="intoDate">
                        <el-date-picker
                            v-model="form.intoDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="handleResetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'PersonApplyRoom',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            personList: [],
            storeyList: [
                // {
                //     building: {
                //         id: 1,
                //         name: '1号楼',
                //     },
                //     storey: {
                //         id: 1,
                //         name: '2层',
                //     },
                //     roomList: [
                //         {
                //             id: 1,
                //             name: '201室',
                //             bed: 6,
                //             type: '男宿',
                //             notUsedBed: 4,
                //         },
                //         {
                //             id: 2,
                //             name: '202室',
                //             bed: 5,
                //             type: '男宿',
                //             notUsedBed: 3,
                //         }
                //     ],
                // },
                // {
                //     building: {
                //         id: 2,
                //         name: '2号楼',
                //     },
                //     storey: {
                //         id: 2,
                //         name: '2层',
                //     },
                //     roomList: [
                //         {
                //             id: 3,
                //             name: '201室',
                //             bed: 6,
                //             type: '男宿',
                //             notUsedBed: 4,
                //         },
                //         {
                //             id: 4,
                //             name: '202室',
                //             bed: 8,
                //             type: '男宿',
                //             notUsedBed: 3,
                //         }
                //     ],
                // },
            ],
            form: {
                personIdList: [],
                roomId: '',
                intoDate: '',
                notUsedBed: '',
            },
            room: {
                roomTypeName: '-',
                checkinNum: '-',
            },
            rules: {
                roomId: [{required: true, message: '请选择房间', trigger: 'change'}],
                intoDate: [{required: true, message: '请选择入住时间', trigger: 'change'}],
                notUsedBed: [{ validator: this.checkNotUsedBed, trigger: 'change'}],
            },
            submitting: false
        }
    },
    computed: {
        roomCount() {
            let count = 0;
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    count += storey.roomList.length;
                }
            });
            return count;
        },
        bedCount() {
            let bedCount = 0;
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        bedCount += room.checkinNum;
                    });
                }
            });
            return bedCount;
        },
    },
    created() {
        if (this.params.personList) {
            this.personList = this.params.personList;
            this.form.personIdList = this.params.personList.map(x => x.id);
        }
        this.loadStoreyList();
    },
    methods: {
        ...common,
        ...verify,
        loadStoreyList() {
            let params = {};
            params.supplierId = this.params.subcontractorId;
            let loading = this.load();
            http.get("v1/build/roomList", { params: params }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    
                    let map = {};
                    data.forEach(x => {
                        let item = map[x.floorId];
                        if (!item) {
                            item = {
                                building: {
                                    id: x.buildId,
                                    name: x.buildName,
                                },
                                storey: {
                                    id: x.floorId,
                                    name: x.floorName,
                                },
                                roomList: [],
                            };

                            map[x.floorId] = item;
                        }
                        item.roomList.push({id: x.roomId, name: x.roomName, ...x});
                    });

                    let arr = [];
                    for (let key in map) {
                        let item = map[key];
                        arr.push(item);
                    }

                    this.storeyList = arr;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let data = {};
                    data.buildId = this.form.roomId;
                    data.checkinWorkerIds = this.form.personIdList;
                    data.day = this.form.intoDate;
                    this.submitting = true;
                    http.post('v1/worker/workerCheckin', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('申请成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleRoomClick(room) {
            this.form.roomId = room.id;
            this.form.notUsedBed = room.notUsedBed;
            this.room = room;
            this.$refs.form.validateField("notUsedBed");
        },
        handlePropertyAllChange(value) {
            if (value) {
                this.form.property = this.propertyList.map(x => x.id);
            } else {
                this.form.property = [];
            }
            this.property.isIndeterminate = false;
        },
        handlePropertyChange() {
            if (this.form.property.length == this.propertyList.length) {
                this.property.all = true;
                this.property.isIndeterminate = false;
            } else {
                if (this.form.property.length == 0) {
                    this.property.all = false;
                    this.property.isIndeterminate = false;
                } else {
                    this.property.isIndeterminate = true;
                }
            }
        },
        handleResetForm() {
            this.room = {
                bed: '-',
                type: '-',
                notUsedBed: '-',
            };
            this.resetForm();
        },
        checkNotUsedBed (rule, value, callback) {
            if (this.room.notUsedBed != '-' && value < this.form.personIdList.length) {
                callback(new Error('可入住床位不足'));
            } else {
                callback();
            }
        },
    }
};
</script>

<style scoped>
</style>