let url = "ws://127.0.0.1:9920/idcard"

export default { 
    UNCONNECTED: 10, // 未连接
    CONNECTING: 11, // 开始连接
    CONNECTED: 12, // 已连接
    DEVICE_UNINITIALIZED: -1, // 设备状态未初始化
    DEVICE_CONNECTED: 1, // 设备已连接
	DEVICE_UNCONNECTED: 2, // 设备未连接
    setup(optins) {
        let self = this;
        if (optins.socket) {
            return;
        }
        
        optins.connect = () => {
            optins.socket = new WebSocket(url);

            if (optins && optins.handleStatusChange) {
                optins.handleStatusChange.call(optins, self.CONNECTING);
            }

            optins.socket.addEventListener("open", () => {
                if (optins && optins.handleStatusChange) {
                    optins.handleStatusChange.call(optins, self.CONNECTED);
                }
            });
    
            optins.socket.addEventListener("close", () => {
                if (optins && optins.handleClose) {
                    optins.handleClose.call(optins);
                }
                if (optins && optins.handleStatusChange) {
                    optins.handleStatusChange.call(optins, self.UNCONNECTED);
                }
                optins.socket = null;
            });
    
            optins.socket.addEventListener("error", event => {
                if (optins && optins.handleError) {
                    optins.handleError.call(optins, event);
                }
                optins.handleStatusChange.call(optins, self.UNCONNECTED);
                optins.close();
            });
    
            optins.socket.addEventListener("message", event => {
                try {
                    let ret = JSON.parse(event.data)
                    if (ret.code == 0) {
                        switch(ret.msg) {
                            case "DEVICE_STATUS": {
                                if (optins && optins.handleStatusChange) {
                                    optins.handleStatusChange.call(optins, ret.data);
                                }
                                break;
                            }
                            case "IDCARD_INFO": {
                                if (optins && optins.handleIDCardInfo) {
                                    optins.handleIDCardInfo.call(optins, ret.data);
                                }
                                break;
                            }
                        }
                    } else {
                        if (optins && optins.handleError) {
                            optins.handleError.call(optins, ret);
                        }
                        optins.handleStatusChange.call(optins, self.UNCONNECTED);
                        optins.close();
                    }
                } catch (err) {
                    if (optins && optins.handleError) {
                        optins.handleError.call(optins, err);
                    }
                    optins.handleStatusChange.call(optins, self.UNCONNECTED);
                    optins.close();
                }
            });
        };

        optins.send = (code, msg, data) => {
            if (!optins.socket) {
                return false;
            }
    
            let d = JSON.stringify({
                code,
                msg,
                data
            })
    
            optins.socket.send(d);
            return true;
        };

        optins.close = () => {
            if (!optins.socket) {
                return false;
            }
    
            optins.socket.close();
            optins.socket = null;
        };
    },
}