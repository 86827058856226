<template>
    <div class="dialog-inner layout-image" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <div class="layout-image-left">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label-width="0" prop="numPhoto">
                            <UploadImage
                                text="身份证照片"
                                :base-url="BASE_URL"
                                :upload-url="BASE_URL + 'v1/upload-img'"
                                :image-url="numPhoto"
                                @success="handleUploadNumPhotoChange"
                                @remove="handleUploadNumPhotoRemove"
                            ></UploadImage>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label-width="0" prop="photo">
                            <UploadImage
                                text="近照"
                                :base-url="BASE_URL"
                                :upload-url="BASE_URL + 'v1/upload-img'"
                                @success="handleUploadPhotoChange"
                                @remove="handleUploadPhotoRemove"
                            ></UploadImage>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="layout-image-right">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="name">
                            <el-input type="text" v-model="form.name" size="small" maxlength="50" @blur="fieldTrim(form, 'name')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="sex">
                            <el-radio-group v-model="form.sex">
                                <el-radio :label="0">男</el-radio>
                                <el-radio :label="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <div 
                        v-if="idcardDevice.show"
                        class="device-status" 
                        :class="{
                            'text-info': idcardDevice.status == 0,
                            'text-default': idcardDevice.status == 1,
                            'text-success': idcardDevice.status == 2,
                            'text-danger': idcardDevice.status == 3}">{{ idcardDevice.text }}</div>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="出生日期" prop="birthday">
                            <el-date-picker
                                v-model="form.birthday"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                size="small"
                                clearable>
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="民族" prop="nation">
                            <el-select v-model="form.nation" filterable placeholder="请选择" size="small" clearable>
                                <el-option 
                                    v-for="item in nationList" 
                                    :key="item.name" 
                                    :label="item.name" 
                                    :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号码" prop="numId">
                            <el-input type="text" v-model="form.numId" size="small" maxlength="50" @blur="fieldTrim(form, 'numId')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="籍贯" prop="nativePlace">
                            <el-input type="text" v-model="form.nativePlace" size="small" maxlength="50" @blur="fieldTrim(form, 'nativePlace')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="住址" prop="dizhi">
                            <el-input type="text" v-model="form.dizhi" size="small" maxlength="50" @blur="fieldTrim(form, 'dizhi')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="发证机关" prop="jiguan">
                            <el-input type="text" v-model="form.jiguan" size="small" maxlength="50" @blur="fieldTrim(form, 'jiguan')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="有效期限" prop="time">
                            <div class="term-validity">
                                <el-date-picker
                                    v-if="!permanent"
                                    :key="'date-range'"
                                    v-model="timespan"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd"
                                    size="small"
                                    :clearable="true">
                                </el-date-picker>
                                <el-date-picker
                                    v-else
                                    :key="'start-date'"
                                    v-model="startDate"
                                    type="date"
                                    placeholder="开始日期"
                                    value-format="yyyy-MM-dd"
                                    size="small"
                                    :clearable="true"
                                    @change="handleStartDateChange">
                                </el-date-picker>
                                <div class="permanent"><el-checkbox v-model="permanent" @change="handlePermanentchange">长期</el-checkbox></div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="分包单位" prop="supplierId">
                            <el-select v-model="form.supplierId" filterable placeholder="请选择" size="small">
                                <el-option 
                                    v-for="item in supplierList" 
                                    :key="item.id" 
                                    :label="item.name" 
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工种" prop="workTypeId">
                            <el-select v-model="form.workTypeId" placeholder="请选择" size="small" clearable>
                                <el-option 
                                    v-for="item in workList" 
                                    :key="item.id" 
                                    :label="item.name" 
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="联系方式" prop="tel">
                            <el-input type="text" v-model="form.tel" size="small" maxlength="50" @blur="fieldTrim(form, 'tel')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="submit-row">
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                            <el-button @click="resetForm" size="small">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import UploadImage from "@/components/UploadImage";
import idcard from "@/utils/idcard";
import nationList from "@/data/nation";

export default {
    name: "HouseholdPersonAdd",
    components: {
        UploadImage
    },
    data() {
        return {
            nationList: nationList,
            BASE_URL: http.BASE_URL,
            supplierList: [],
            workList: [],
            startDate: '',
            timespan: [],
            permanent: '',
            numPhoto: '',
            form: {
                numPhoto: "",
                photo: "",
                name: "",
                sex: "",
                birthday: null,
                nation: "",
                numId: "",
                nativePlace: "",
                dizhi: "",
                jiguan: "",
                time: "",
                supplierId: "",
                workTypeId: "",
                tel: "",
            },
            rules: {
                // numPhoto: [{ required: true, message: "身份证头像不能为空" }],
                // photo: [{ required: true, message: "近照头像不能为空" }],
                name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
                sex: [{ required: true, message: "请选择性别", trigger: "change" }],
                // birthday: [{ required: true, message: "请选择出生日期", trigger: "change" }],
                // nation: [{ required: true, message: "民族不能为空", trigger: "blur" }],
                // numId: [{ required: true, message: "身份证号不能为空", trigger: "blur" }],
                // nativePlace: [{ required: true, message: "籍贯不能为空", trigger: "blur" }],
                // dizhi: [{ required: true, message: "住址不能为空", trigger: "blur" }],
                // jiguan: [{ required: true, message: "发证机关不能为空", trigger: "blur" }],
                // time: [{ required: true, message: "请选择有效期限", trigger: "change" }],
                supplierId: [{ required: true, message: "请选择分包单位", trigger: "change" }],
                // workTypeId: [{ required: true, message: "请选择工种", trigger: "change" }],
                // tel: [{ required: true, message: "联系方式不能为空", trigger: "blur" }],
            },
            submitting: false,
            idcardDevice: {
                show: false,
                status: 0,
                text: '',
            },
        };
    },
    watch: {
        ["timespan"](value) {
            if (value == null || value.length == 0) {
                if (this.permanent && (this.startDate != "" && this.startDate != null)) {
                    this.form.time =  this.startDate + ",长期";
                    return;
                }
                this.form.time = "";
                return;
            }
            this.permanent = false;
            this.startDate = "";
            this.form.time = value[0] + "," + value[1];
        },
    },
    created() {
        this.init();
        this.setupIDCard();
    },
    beforeDestroy() {
        this.destroyIDCard();
    },
    methods: {
        ...common,
        ...verify,
        setupIDCard() {
            this.idcard = {
                vm: this,
                done: false,
                timeId: null,
                status: idcard.UNCONNECTED,
                handleStatusChange(status) {
                    if (this.done) {
                        return;
                    }
                    if (this.status == status) {
                        return;
                    }
                    switch(status) {
                        case idcard.UNCONNECTED: // 未连接
                            if (this.status == idcard.CONNECTING) {
                                this.vm.setIDCardStatus(true, 3, "连接失败");
                                if (this.timeId != null) {
                                    clearTimeout(this.timeId);
                                }
                                this.timeId = setTimeout(() => {
                                    this.timeId = null;
                                    this.vm.idcard.connect();
                                }, 1000);
                            } else {
                                this.vm.setIDCardStatus(true, 3, "连接断开");
                            }
                            break;
                        case idcard.CONNECTING: // 开始连接
                            this.vm.setIDCardStatus(true, 0, "正在连接身份证阅读器");
                            break;
                        case idcard.CONNECTED: // 已连接
                            break;
                        case idcard.DEVICE_UNINITIALIZED: // 设备状态未初始化
                            this.vm.setIDCardStatus(true, 0, "正在初始化身份证阅读器");
                            break;
                        case idcard.DEVICE_CONNECTED: // 设备已连接
                            this.vm.setIDCardStatus(true, 2, "身份证阅读器状态正常");
                            break;
                        case idcard.DEVICE_UNCONNECTED: // 设备未连接
                            this.vm.setIDCardStatus(true, 3, "身份证阅读器未连接");
                            break;
                        default:
                            this.vm.setIDCardStatus(true, 3, "未知状态");
                            break;
                    }
                    this.status = status;
                },
                handleIDCardInfo(data) {
                    if (this.done) {
                        return;
                    }

                    this.vm.form.numPhoto = data.Avatar;
                    this.vm.numPhoto = data.Avatar;
                    this.vm.form.name = data.Name;
                    this.vm.form.sex = this.vm.sexToNum(data.Sex);
                    this.vm.form.birthday = data.Birthday;
                    this.vm.form.nation = data.Nation + "族";
                    this.vm.form.dizhi = data.Address;
                    this.vm.form.numId = data.IDCode;
                    this.vm.form.jiguan = data.Department;
                    if(data.EndDate == "长期") {
                        this.vm.startDate = data.StartDate.replace(/\./g, "-");
                        this.vm.permanent = true;
                        this.vm.timespan = [];
                    } else {
                        this.vm.timespan = this.vm.fixTermValidity(data.StartDate, data.EndDate);
                    }
                },
            };
            idcard.setup(this.idcard);
            this.idcard.connect();
        },
        destroyIDCard() {
            this.idcard.done = true;
            if (this.idcard.timeId != null) {
                clearTimeout(this.idcard.timeId);
                this.idcard.timeId == null;
            }
            this.idcard.close();
        },
        setIDCardStatus(show, status, text) {
            this.idcardDevice = {
                show: show,
                status: status,
                text: text,
            };
        },
        sexToNum(sex) {
            if (sex == "男") {
                return 0;
            } else if (sex == "女") {
                return 1;
            }

            return '';
        },
        fixTermValidity(start, end) {
            start = start.replace(/\./g, '-');
            end = end.replace(/\./g, '-');
            return [start, end];
        },
        init() {
            this.loadSupperList();
            this.loadWorkTypeList();
        },
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadWorkTypeList() {
            http.get("v1/supplier/selectWorkType").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.workList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post("v1/worker/add", this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess("添加成功", () => {
                                this.$emit("done");
                            });
                        }
                        else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                }
                else {
                    return false;
                }
            });
        },
        handleUploadPhotoChange(data) {
            this.form.photo = data.url;
        },
        handleUploadPhotoRemove() {
            this.form.photo = "";
        },
        handleUploadNumPhotoChange(data) {
            this.form.numPhoto = data.url;
        },
        handleUploadNumPhotoRemove() {
            this.form.numPhoto = "";
            this.numPhoto = "";
        },
        handleStartDateChange() {
            this.timespan = [];
        },
        handlePermanentchange(value) {
            this.timespan = [];
            if (!value) {
                this.startDate = "";
            }
            this.timeClearValidate();
        },
        timeClearValidate() {
            this.$nextTick(() => {
                if(this.$refs.form) {
                    this.$refs.form.clearValidate("time");
                }
            });
        },
    },
};
</script>

<style scoped>
</style>