<template>
    <div class="dialog-inner layout-image" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <div class="layout-image-left">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label-width="0" prop="numPhoto">
                            <UploadImage
                                text="身份证照片"
                                :image-url="form.numPhoto ? BASE_URL + form.numPhoto.substring(1) : ''"
                                :base-url="BASE_URL"
                                :upload-url="BASE_URL + 'v1/upload-img'"
                                @success="handleUploadNumPhotoChange"
                                @remove="handleUploadNumPhotoRemove"
                            ></UploadImage>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label-width="0" prop="photo">
                            <UploadImage
                                text="近照"
                                :image-url="form.photo ? BASE_URL + form.photo.substring(1) : ''"
                                :base-url="BASE_URL"
                                :upload-url="BASE_URL + 'v1/upload-img'"
                                @success="handleUploadPhotoChange"
                                @remove="handleUploadPhotoRemove"
                            ></UploadImage>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="layout-image-right">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="name">
                            <el-input type="text" v-model="form.name" size="small" maxlength="50" @blur="fieldTrim(form, 'name')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="sex" clearable>
                            <el-radio-group v-model="form.sex">
                                <el-radio :label="0">男</el-radio>
                                <el-radio :label="1">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="出生日期" prop="birthday" clearable>
                            <el-date-picker
                                v-model="form.birthday"
                                type="date"
                                placeholder="选择日期"
                                value-format="yyyy-MM-dd"
                                size="small">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="民族" prop="nation">
                            <el-select v-model="form.nation" filterable placeholder="请选择" size="small">
                                <el-option 
                                    v-for="item in nationList" 
                                    :key="item.name" 
                                    :label="item.name" 
                                    :value="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="身份证号码" prop="numId">
                            <el-input type="text" v-model="form.numId" size="small" maxlength="50" @blur="fieldTrim(form, 'numId')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="籍贯" prop="nativePlace">
                            <el-input type="text" v-model="form.nativePlace" size="small" maxlength="50" @blur="fieldTrim(form, 'nativePlace')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="住址" prop="dizhi">
                            <el-input type="text" v-model="form.dizhi" size="small" maxlength="50" @blur="fieldTrim(form, 'dizhi')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="发证机关" prop="jiguan">
                            <el-input type="text" v-model="form.jiguan" size="small" maxlength="50" @blur="fieldTrim(form, 'jiguan')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="有效期限" prop="time">
                            <div class="term-validity">
                                <el-date-picker
                                    v-if="!permanent"
                                    :key="'date-range'"
                                    v-model="timespan"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    value-format="yyyy-MM-dd"
                                    size="small"
                                    :clearable="true">
                                </el-date-picker>
                                <el-date-picker
                                    v-else
                                    :key="'start-date'"
                                    v-model="startDate"
                                    type="date"
                                    placeholder="开始日期"
                                    value-format="yyyy-MM-dd"
                                    size="small"
                                    :clearable="true"
                                    @change="handleStartDateChange">
                                </el-date-picker>
                                <div class="permanent"><el-checkbox v-model="permanent" @change="handlePermanentchange">长期</el-checkbox></div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="分包单位" prop="supplierId">
                            <el-select v-model="form.supplierId" filterable placeholder="请选择" size="small">
                                <el-option 
                                    v-for="item in supplierList" 
                                    :key="item.id" 
                                    :label="item.name" 
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工种" prop="workTypeId">
                            <el-select v-model="form.workTypeId" filterable placeholder="请选择" size="small" clearable>
                                <el-option 
                                    v-for="item in workList" 
                                    :key="item.id" 
                                    :label="item.name" 
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="联系方式" prop="tel">
                            <el-input type="text" v-model="form.tel" size="small" maxlength="50" @blur="fieldTrim(form, 'tel')"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="submit-row">
                    <el-col :span="24">
                        <el-form-item>
                            <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                            <el-button @click="resetForm" size="small">重置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import UploadImage from "@/components/UploadImage.vue";
import nationList from "@/data/nation";

export default {
    name: "HouseholdPersonEdit",
    components: {
        UploadImage
    },
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            nationList: nationList,
            BASE_URL: http.BASE_URL,
            supplierList: [],
            workList: [],
            startDate: '',
            timespan: [],
            permanent: '',
            form: {
                id: "",
                numPhoto: "",
                photo: "",
                name: "",
                sex: "",
                birthday: null,
                nation: "",
                numId: "",
                nativePlace: "",
                dizhi: "",
                jiguan: "",
                time: "",
                supplierId: "",
                workTypeId: "",
                tel: "",
            },
            rules: {
                // numPhoto: [{ required: true, message: "身份证头像不能为空" }],
                // photo: [{ required: true, message: "近照头像不能为空" }],
                name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
                sex: [{ required: true, message: "请选择性别", trigger: "change" }],
                // birthday: [{ required: true, message: "请选择出生日期", trigger: "change" }],
                // nation: [{ required: true, message: "民族不能为空", trigger: "blur" }],
                // numId: [{ required: true, message: "身份证号不能为空", trigger: "blur" }],
                // nativePlace: [{ required: true, message: "籍贯不能为空", trigger: "blur" }],
                // dizhi: [{ required: true, message: "住址不能为空", trigger: "blur" }],
                // jiguan: [{ required: true, message: "发证机关不能为空", trigger: "blur" }],
                // time: [{ required: true, message: "请选择有效期限", trigger: "change" }],
                supplierId: [{ required: true, message: "请选择分包单位", trigger: "change" }],
                // workTypeId: [{ required: true, message: "请选择工种", trigger: "change" }],
                // tel: [{ required: true, message: "联系方式不能为空", trigger: "blur" }],
            },
            submitting: false
        };
    },
    watch: {
        ["timespan"](value) {
            if (value == null || value.length == 0) {
                if (this.permanent && (this.startDate != "" && this.startDate != null)) {
                    this.form.time =  this.startDate + ",长期";
                    return;
                }
                this.form.time = "";
                return;
            }
            this.permanent = false;
            this.startDate = "";
            this.form.time = value[0] + "," + value[1];
        },
    },
    created() {
        this.init();
        this.form.id = this.params.id;
        this.loadData();
    },
    methods: {
        ...common,
        ...verify,
        init() {
            this.loadSupperList();
            this.loadWorkTypeList();
        },
        loadData() {
            let params = {};
            params.id = this.params.id;
            http.get("v1/worker/detail", { params: params }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.form = req.data.data;
                    this.form.sex = Number(this.form.sex);
                    this.form.supplierId = Number(this.form.supplierId);
                    let arr = this.form.time.split(",");
                    if (arr && arr.length > 1) {
                        if(arr[1] == '长期'){
                            this.startDate = arr[0];
                            this.permanent = true;
                        } else {
                            this.timespan = arr;
                        }
                    }
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadWorkTypeList() {
            http.get("v1/supplier/selectWorkType").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.workList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    http.post("v1/worker/edit", this.form).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess("编辑成功", () => {
                                this.$emit("done");
                            });
                        }
                        else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                }
                else {
                    return false;
                }
            });
        },
        handleUploadPhotoChange(data) {
            this.form.photo = data.url;
        },
        handleUploadPhotoRemove() {
            this.form.photo = "";
        },
        handleUploadNumPhotoChange(data) {
            this.form.numPhoto = data.url;
        },
        handleUploadNumPhotoRemove() {
            this.form.numPhoto = "";
        },
        handleStartDateChange() {
            this.timespan = [];
        },
        handlePermanentchange(value) {
            this.timespan = [];
            if (!value) {
                this.startDate = "";
            }
            this.timeClearValidate();
        },
        timeClearValidate() {
            this.$nextTick(() => {
                if(this.$refs.form) {
                    this.$refs.form.clearValidate("time");
                }
            });
        },
    },
};
</script>

<style scoped>
</style>