<template>
    <div class="image-box">
        <el-image 
            :style="{width: cWidth, height: cHeight, 'line-height': cHeight}"
            :src="previewUrl" 
            :preview-src-list="previewList">
        </el-image>
    </div>
</template>

<script>
export default {
    name: 'ImageBox',
    props: {
        width: {
            type: [Number,String],
            default() {
                return '180px';
            },
        },
        height: {
            type: [Number,String],
            default() {
                return '180px';
            },
        },
        imageUrl: {
            type: String,
            default() {
                return '';
            },
        },
        imageList: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data(){
        return {
            previewList: this.imageList,
            previewUrl: this.imageUrl,
        }
    },
    watch: {
        ['imageUrl']: {
            immediate: true,
            handler (value) {
                this.previewUrl = value;
            }
        },
        ['imageList']: {
            immediate: true,
            handler (value) {
                this.previewList = value;
            }
        },
    },
    computed: {
        cWidth: {
            get() {
                if (typeof this.width === 'number') {
                    return this.width+'px';
                }
                return this.width;
            }
        },
        cHeight: {
            get() {
                if (typeof this.height === 'number') {
                    return this.height+'px';
                }
                return this.height;
            }
        },
    },
    created() {

    },
    methods: {

    }
};
</script>

<style scoped>
    .image-box /deep/ .el-image {
        text-align: center;
        background-color: #f3f3f3;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
    }
    .image-box /deep/ .el-image::before {
        content: " ";
        display: inline-block;
        width: 0;
        height: 100%;
        overflow: hidden;
        vertical-align: middle;
    }
    .image-box /deep/ .el-image img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
        position: relative;
        top: -1px;
    }
</style>