<template>
    <div class="upload-image-box">
        <div class="upload-box" :class="{hide: previewStatus}">
            <el-upload
                ref="upload"
                class="upload-image"
                drag
                :action="uploadUrl"
                :on-success="handleSuccess">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text"><em>{{ text }}</em></div>
            </el-upload>
        </div>
        <ul v-if="previewStatus" class="el-upload-list el-upload-list--picture-card">
            <li tabindex="0" class="el-upload-list__item is-ready">
                <img :src="previewUrl" class="el-upload-list__item-thumbnail">
                <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" v-if="onPreview" @click="onPreview(data)"><i class="el-icon-zoom-in"></i></span>
                    <span class="el-upload-list__item-delete" v-if="onDownload" @click="onDownload(data)"><i class="el-icon-download"></i></span>
                    <span class="el-upload-list__item-delete" @click="handleRemove()"><i class="el-icon-delete"></i></span>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'UploadImage',
    props: {
        text: {
            type: String,
            default() {
                return '图片上传';
            },
        },
        baseUrl: {
            type: String,
            default() {
                return '';
            },
        },
        uploadUrl: {
            type: String,
            default() {
                return '';
            },
        },
        imageUrl: {
            type: String,
            default() {
                return '';
            },
        },
        onPreview: {
            type: Function,
        },
        onDownload: {
            type: Function,
        },
    },
    data(){
        return {
            previewStatus: this.imageUrl != '',
            data: {},
            previewUrl: this.imageUrl,
        }
    },
    watch: {
        ['imageUrl']: {
            immediate: true,
            handler (value) {
                this.previewStatus = value != '';
                this.previewUrl = value;
            }
        }
    },

    created() {
        
    },
    methods: {
        // handleSuccess(response, file, fileList) {
        handleSuccess(response) {
            let { code, data } = response;
            if (code == 0) {
               this.previewUrl = this.baseUrl + data.url;
               this.previewStatus = true;
               this.data = data;
               this.$emit("success", data);
            }
        },
        handleRemove() {
            this.$refs.upload.clearFiles();
            this.previewUrl = '';
            this.previewStatus = false;
            this.$emit("remove");
        },
    }
};
</script>

<style scoped>
.upload-image-box {
    position: relative;
    display: inline-block;
}
.upload-image-box /deep/:hover .el-upload-dragger .el-icon-upload {
    color: #409eff;
}
.upload-image-box /deep/ .el-upload-list--text {
    display: none;
}
.upload-image-box .hide {
    opacity: 0;
}
.upload-image-box .el-upload-list {
    position: absolute;
    top: 0;
    left: 0;
}
.upload-image-box .el-upload-list--picture-card .el-upload-list__item {
    width: 180px;
    height: 180px;
    line-height: 180px;
    text-align: center;
    background-color: #f3f3f3;
}
.upload-image-box .el-upload-list--picture-card .el-upload-list__item::before {
    content: " ";
    display: inline-block;
    width: 0;
    height: 100%;
    overflow: hidden;
    vertical-align: middle;
}
.upload-image-box /deep/ .el-upload-dragger {
    width: 180px;
}

.upload-image-box .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    position: relative;
    top: -2px;
}
</style>