<template>
    <div class="frame-content">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-input v-model="filters.name" placeholder="姓名" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-input v-model="filters.numId" placeholder="身份证" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option 
                            v-for="item in supplierList" 
                            :key="item.id" 
                            :label="item.name" 
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-input v-model="filters.nativePlace" placeholder="籍贯" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.sex" clearable placeholder="性别" size="small">
                        <el-option label="男" value="0"></el-option>
                        <el-option label="女" value="1"></el-option>
                    </el-select>
                </div>
                <!-- <div class="inline mr-8">
                    <el-input v-model="filters.roomIdentifier" placeholder="宿舍编号" size="small"></el-input>
                </div> -->
                <div class="inline mr-8">
                    <el-select v-model="filters.status" clearable placeholder="入住状态" size="small">
                        <el-option label="未入住" value="0"></el-option>
                        <el-option label="已入住" value="1"></el-option>
                        <el-option label="已退宿" value="2"></el-option>
                    </el-select>
                </div>
                <div class="inline date mr-8">
                    <el-date-picker
                        v-model="filters.inDate"
                        type="date"
                        placeholder="入住日期"
                        size="small"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </div>
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="topbar">
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd" :disabled="$store.state.org.type != 2">新增</el-button>
                    </div>
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleBatchApplyRoom" :disabled="$store.state.org.type != 2">申请房间</el-button>
                    </div>
                    <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleBatchCheckOut" :disabled="$store.state.org.type != 2">退宿</el-button>
                    </div>
                    <div class="inline mr-8">
                        <el-button type="danger" size="small" @click="handleDeleteAll">删除</el-button>
                    </div>
                    <!-- <div class="inline mr-8">
                        <el-button type="primary" size="small" @click="handleAdd">导入人员</el-button>
                    </div> -->
                </div>
                <div class="datatable person-tab">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="身份信息"
                            min-width="300">
                            <template slot-scope="scope">
                                <div class="avatar">
                                    <img v-if="scope.row.photo" :src="BASE_URL+scope.row.photo">
                                    <i v-else class="el-icon-user-solid"></i>
                                </div>
                                <ul class="info">
                                    <li class="name">{{ scope.row.name }}</li>
                                    <li class="sex">{{ scope.row.sex }}</li>
                                    <li class="age">{{ scope.row.age }}岁</li>
                                    <li class="nation">{{ scope.row.nation }}</li>
                                    <li class="native-place">{{ scope.row.nativePlace }}</li>
                                    <li class="id-number">{{ scope.row.numId }}</li>
                                </ul>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="工作信息"
                            min-width="280">
                            <template slot-scope="scope">
                                <ul class="work">
                                    <li>分包单位：{{ scope.row.supplierName }}</li>
                                    <li class="type-work">工种：<span>{{ scope.row.workName }}</span></li>
                                    <li>联系方式：{{ scope.row.tel }}</li>
                                </ul>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="宿舍信息"
                            align="center"
                            min-width="220">
                            <template slot-scope="scope">
                                <ul class="room">
                                    <li>房间编号：{{ scope.row.buildName }}-{{ scope.row.floorName }}-{{ scope.row.roomName }}</li>
                                    <li>房型：{{ scope.row.roomTypeName }}</li>
                                </ul>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="入住信息"
                            align="center"
                            min-width="220">
                            <template slot-scope="scope">
                                <ul class="live">
                                    <li>入住状态：
                                        <el-tag v-if="scope.row.status == 0" type="warning" size="mini">未入住</el-tag>
                                        <el-tag v-if="scope.row.status == 1" type="success" size="mini">已入住</el-tag>
                                        <el-tag v-if="scope.row.status == 2" type="info" size="mini">已退宿</el-tag>
                                    </li>
                                    <li>入住日期：{{ scope.row.inDate }}</li>
                                    <li>退宿日期：{{ scope.row.outDate }}</li>
                                </ul>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="action"
                            label="操作"
                            align="left"
                            width="245"
                            class-name="action">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="primary"
                                    v-if="scope.row.status == 0"
                                    @click="handleApplyRoom(scope.$index, scope.row)">申请宿舍</el-button>
                                <!-- <el-button
                                    size="mini"
                                    type="primary"
                                    v-if="scope.row.status == 1"
                                    @click="handleEdit(scope.$index, scope.row)">换宿</el-button> -->
                                <el-button
                                    size="mini"
                                    type="primary"
                                    v-if="scope.row.status == 1"
                                    @click="handleCheckOut(scope.$index, scope.row)">退宿</el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                                <el-button
                                    size="mini"
                                    type="primary"
                                    @click="handleDetail(scope.$index, scope.row)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                title="住户信息详情"
                v-if="detailDialog.create"
                :visible.sync="detailDialog.visible"
                :close-on-click-modal="false"
                @closed="handleDetailClosed"
                class="custom-dialog small-dialog"><!-- big-dialog small-dialog mini-dialog  -->
                <PersonDetail :params="detailDialog.params"></PersonDetail>
            </el-dialog>

            <el-dialog
                title="新增住户信息"
                v-if="addDialog.create"
                :visible.sync="addDialog.visible"
                :close-on-click-modal="false"
                @closed="handleAddClosed"
                class="custom-dialog small-dialog">
                <PersonAdd @done="handleAddDone"></PersonAdd>
            </el-dialog>

            <el-dialog
                title="编辑住户信息"
                v-if="editDialog.create"
                :visible.sync="editDialog.visible"
                :close-on-click-modal="false"
                @closed="handleEditClosed"
                class="custom-dialog small-dialog">
                <PersonEdit :params="editDialog.params" @done="handleEditDone"></PersonEdit>
            </el-dialog>

            <el-dialog
                title="申请房间"
                v-if="applyRoomDialog.create"
                :visible.sync="applyRoomDialog.visible"
                :close-on-click-modal="false"
                @closed="handleApplyRoomClosed"
                class="custom-dialog small-dialog">
                <PersonApplyRoom :params="applyRoomDialog.params" @done="handleApplyRoomDone"></PersonApplyRoom>
            </el-dialog>

            <el-dialog
                title="退宿"
                v-if="checkOutDialog.create"
                :visible.sync="checkOutDialog.visible"
                :close-on-click-modal="false"
                @closed="handleCheckOutClosed"
                class="custom-dialog mini-dialog">
                <PersonCheckOut :params="checkOutDialog.params" @done="handleCheckOutDone"></PersonCheckOut>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import PersonDetail from './PersonDetail'
import PersonAdd from './PersonAdd'
import PersonEdit from './PersonEdit'
import PersonApplyRoom from './PersonApplyRoom'
import PersonCheckOut from './PersonCheckOut'

export default {
    name: 'HouseholdPerson',
    components: {
        PersonDetail,
        PersonAdd,
        PersonEdit,
        PersonApplyRoom,
        PersonCheckOut,
    },
    data() {
        return {
            BASE_URL: http.BASE_URL,
            supplierList:[],
            filters: {
                name: '',
                numId: '',
                supplierId: '',
                nativePlace: '',
                sex: '',
                // roomIdentifier: '',
                status: '',
                inDate: '',
            },

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            tableLoading: false,

            detailDialog: {
                create: false,
                visible: false,
                params: {},
            },

            addDialog: {
                create: false,
                visible: false,
            },

            editDialog: {
                create: false,
                visible: false,
                params: {},
            },

            applyRoomDialog: {
                create: false,
                visible: false,
                params: {},
            },
            checkOutDialog: {
                create: false,
                visible: false,
                params: {},
            },
        };
    },
    created () {
        this.loadTable();
        this.loadSupperList();
    },
    methods: {
        ...common,
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        // 加载表格数据
        loadTable() {
            let params = {...this.filters};

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.tableLoading = true;
            http.get('v1/worker/page', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (req.data.code == http.SUCCESS) {
                    this.tableData = req.data.data.records;
                    this.totalNum = req.data.data.total;
                    this.curPageNum = this.pageNum;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        // 详情弹窗口
        handleDetail(index, row) {
            this.detailDialog = { create: true, params: row };
            this.$nextTick(() => {
                this.detailDialog = { ...this.detailDialog, visible: true };
            });
        },
        handleDetailClosed() {
            this.detailDialog = { create: false, visible: false, params: {} };
        },

        // 添加弹窗口
        handleAdd() {
            this.addDialog = { create: true, visible: false };
            this.$nextTick(() => {
                this.addDialog = { create: true, visible: true };
            });
        },
        handleAddDone() {
            this.addDialog = { visible: false };
            this.loadTable();
        },
        handleAddClosed() {
            this.addDialog = { create: false, visible: false };
        },

        // 编辑弹窗口
        handleEdit(index, row) {
            this.editDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.editDialog = {  create: true, visible: true, params: row };
            });
        },
        handleEditDone() {
            this.editDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleEditClosed() {
            this.editDialog = { create: false, visible: false, params: {} };
        },

        // 申请房间
        handleApplyRoom(index, row) {
            let params = {};
            params.subcontractorId = row.supplierId;
            params.subcontractorName = row.supplierName;
            params.personList = [row];
            this.applyRoomDialog = { create: true, visible: false, params: params };
            this.$nextTick(() => {
                this.applyRoomDialog = {  create: true, visible: true, params: params };
            });
        },
        handleApplyRoomDone() {
            this.applyRoomDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleApplyRoomClosed() {
            this.applyRoomDialog = { create: false, visible: false, params: {} };
        },

        handleBatchApplyRoom() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择人员！');
            } else {
                let params = {};
                let item;
                let subcontractorName = this.$refs.currentTable.selection[0].supplierName;
                let personList = [];
                for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                    item = this.$refs.currentTable.selection[i];
                    if (subcontractorName != item.supplierName) {
                        this.showError('不能选择不同分包单位的人员！');
                        return;
                    }
                    if (item.status == 1) {
                        this.showError('不能选择已入住的人员！');
                        return;
                    }
                    personList.push(item);
                }

                params.subcontractorId = personList[0].supplierId;
                params.subcontractorName = subcontractorName;
                params.personList = personList;
                this.applyRoomDialog = { create: true, visible: false, params: params };
                this.$nextTick(() => {
                    this.applyRoomDialog = {  create: true, visible: true, params: params };
                });
            }
        },

        // 退宿
        buildFullName(buildName, floorName, roomName) {
            let fullName = '';

            if (buildName != null && buildName != '') {
                fullName += buildName;
            } else {
                return '';
            }

            if (floorName != null && floorName != '') {
                fullName += '-' + floorName;
            } else {
                return '';
            }

            if (roomName != null && roomName != '') {
                fullName += '-' + roomName;
            } else {
                return '';
            }

            return fullName;
        },

        handleBatchCheckOut() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择人员！');
            } else {
                let params = {};
                let item;
                let row = this.$refs.currentTable.selection[0];
                let subcontractorName = row.supplierName;
                let build = this.buildFullName(row.buildName, row.floorName, row.roomName);

                if (build == '') {
                    this.showError('不能选择未分配房间的人员！');
                    return;
                }

                let personList = [];
                for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                    item = this.$refs.currentTable.selection[i];
                    if (subcontractorName != item.supplierName) {
                        this.showError('不能选择不同分包单位的人员！');
                        return;
                    }
                    let itemBuild = this.buildFullName(item.buildName, item.floorName, item.roomName);
                    if (itemBuild == '') {
                        this.showError('不能选择未分配房间的人员！');
                        return;
                    }

                    if (itemBuild != build) {
                        this.showError('不能选择不同房间的人员！');
                        return;
                    }

                    personList.push(item);
                }
                
                params.subcontractorName = subcontractorName;
                params.personList = personList;
                
                this.handleCheckOut(-1, params);
            }
        },

        handleCheckOut(index, row) {
            this.checkOutDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.checkOutDialog = {  create: true, visible: true, params: row };
            });
        },
        handleCheckOutDone() {
            this.checkOutDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleCheckOutClosed() {
            this.checkOutDialog = { create: false, visible: false, params: {} };
        },

        // handleDisable(index, row, status) {
        //     http.put(http.MOCK_Person_API_URL + 'demo/disable', { id: row.id, status: status }).then(req => {
        //         if (req.data.code == http.SUCCESS) {
        //             this.loadTable();
        //             this.showSuccess('禁用成功！');
        //         } else {
        //             this.showError(req.data.msg);
        //         }
        //     });
        // },
        // handleDelete(index, row) {
        //     this.confirm('确认删除？', function () {
        //         http.delete(http.MOCK_Person_API_URL + 'demo/' + row.id).then(req => {
        //             if (req.data.code == http.SUCCESS) {
        //                 this.loadTable();
        //                 this.showSuccess('删除成功！');
        //             } else {
        //                 this.showError(req.data.msg);
        //             }
        //         });
        //     });
        // },
        handleDeleteAll() {
            if (this.$refs.currentTable.selection.length == 0) {
                this.showError('请选择要删除的人员！');
            } else {
                this.confirm('确认删除所选人员？', function () {
                    let idarr = [];
                    for (let i = 0; i < this.$refs.currentTable.selection.length; i++) {
                        idarr.push(this.$refs.currentTable.selection[i].id);
                    }
                    http.post('v1/worker/deleteAll', { ids: idarr }).then(req => {
                        if (req.data.code == http.SUCCESS) {
                            this.loadTable();
                            this.showSuccess('删除成功！');
                        } else {
                            this.showError(req.data.msg);
                        }
                    });
                });
            }
        },
    }
}
</script>

<style scoped>
</style>
