<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="data-item">
                        <div class="label">人员姓名：</div>
                        <div class="text">{{ name }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="退宿时间：" prop="checkOutDate">
                        <el-date-picker
                            v-model="form.checkOutDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item label="备注：" prop="remark">
                        <el-input type="textarea" v-model="form.remark" :rows="4" maxlength="500"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="handleResetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'PersonCheckOut',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            name: '',
            form: {
                personIdList: [],
                roomId: '',
                checkOutDate: '',
                remark: '',
            },
            rules: {
                checkOutDate: [{required: true, message: '请选择退宿时间', trigger: 'change'}],
                remark: [{ validator: this.checkRemark, trigger: 'blur'}],
            },
            submitting: false
        }
    },
    created() {
        if (this.params.personList) {
            this.roomId = this.params.personList[0].buildId;
            let nameArr = [];
            this.params.personList.forEach(person => {
                this.form.personIdList.push(person.id);
                nameArr.push(person.name);
            });
            this.name = nameArr.join('、');
        } else {
            this.roomId = this.params.buildId;
            this.name = this.params.name;
            this.form.personIdList.push(this.params.id);
        }
    },
    methods: {
        ...common,
        ...verify,
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let data = {};
                    data.buildId = this.roomId;
                    data.checkoutWorkerIds = this.form.personIdList;
                    data.day = this.form.checkOutDate;
                    data.remark = this.form.remark;
                    this.submitting = true;
                    http.post('v1/worker/workerCheckin', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('申请成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        checkRemark (rule, value, callback) {
            if (value.length > 500) {
                callback(new Error('最多'));
            } else {
                callback();
            }
        },
        handleResetForm() {
            this.checkOutDate = '';
            this.remark = '';
            this.$nextTick(() => {
                this.$refs.form.clearValidate("checkOutDate");
            });
        },
    }
};
</script>

<style scoped>
</style>